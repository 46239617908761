<template>
  <a-dropdown v-model="visible" destroy-popup-on-hide :trigger="['click']">
    <slot>
      <Boot :column="value" :more-config="moreConfig" />
    </slot>
    <Overlay
      slot="overlay"
      :columns="columns"
      :selected="value && value.columnId"
      :loading="loading"
      :empty-tips="datasheetId ? '暂无数据' : '请选择视图'"
      @select="onSelect"
    />
  </a-dropdown>
</template>

<script>
import { IWidgetColumn } from "../../widgets/common.interface";
import Overlay from "./Overlay";
import Boot from "./Boot";

import * as utils from "../../utils";

export default {
  components: { Overlay, Boot },
  props: {
    value: Object,
    datasheetId: String,
    // 配置菜单 { alias: boolean; up: boolean, down: boolean, delete: boolean }
    moreConfig: Object
  },
  model: {
    event: "select"
  },
  data() {
    return {
      visible: false,
      loading: false,
      columns: []
    };
  },
  created() {
    this.fetchColumns();
  },
  watch: {
    datasheetId() {
      this.fetchColumns();
    }
  },
  methods: {
    async fetchColumns() {
      this.columns = [];
      if (!this.datasheetId) {
        return;
      }
      this.loading = true;
      try {
        const { data } = await utils.getDatasheetColumns(this.datasheetId);
        this.columns = data;
      } finally {
        this.loading = false;
      }
    },
    onSelect(item) {
      this.visible = false;
      const column = IWidgetColumn.from(item);
      this.$emit("select", column);
    }
  }
};
</script>
