import {
  BaseFormItem,
  SelectDatasheet,
  FilterDatasheet,
  SelectChart,
  SelectRows,
  SelectValues
} from "../../atoms";

export default {
  inheritAttrs: false,
  props: {
    widget: Object,
    datasource: Object
  },
  render() {
    /** @type {import("../../models/widget").default} */
    const widget = this.widget || {};
    /** @type  {import("../type").IWidgetDatasource} */
    const datasource = this.datasource || {};
    return (
      <div>
        <BaseFormItem label="视图数据源" required>
          <SelectDatasheet
            vModel={datasource.datasheetId}
            datasource={datasource}
          />
          <FilterDatasheet
            vModel={datasource.filter}
            datasheet-id={datasource.datasheetId}
          />
        </BaseFormItem>
        <BaseFormItem label="图表类型" required>
          <SelectChart vModel={widget.type} datasource={datasource} />
        </BaseFormItem>
        <BaseFormItem label="维度" required>
          <SelectRows
            columns={datasource.columns}
            datasheet-id={datasource.datasheetId}
            min={1}
            max={1}
          />
        </BaseFormItem>
        <BaseFormItem label="数值" required>
          <SelectValues
            columns={datasource.columns}
            datasheet-id={datasource.datasheetId}
            min={1}
          />
        </BaseFormItem>
      </div>
    );
  }
};
