import { render, staticRenderFns } from "./BootLabel.vue?vue&type=template&id=26a34e32&scoped=true&"
import script from "./BootLabel.vue?vue&type=script&lang=js&"
export * from "./BootLabel.vue?vue&type=script&lang=js&"
import style0 from "./BootLabel.vue?vue&type=style&index=0&id=26a34e32&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26a34e32",
  null
  
)

export default component.exports