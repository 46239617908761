<template>
  <BaseSelection :show-arrow="!showMenu">
    <template v-if="column && column.columnId">
      <i :class="iconClass"></i>
      <Label v-model="column.columnName" ref="label" class="label" />
      <span v-if="showAgg" class="button" @click.stop="onAgg">
        {{ column.aggType | locale }}
      </span>
    </template>
    <span v-else class="placeholder">请选择</span>
    <i
      v-if="showMenu"
      class="button ndl-icon-more-vertical"
      style="margin-right: -6px"
      @click.stop="onMore"
    ></i>
  </BaseSelection>
</template>

<script>
import showContextmenu from "@/components/contextmenu";
import { capitalize } from "@/utils/string";
import { Icon } from "@/constant/column-types";
import Label from "./BootLabel";
import { BaseSelection } from "../base";
import { DimensionType } from "../../widgets/common.interface";
import * as utils from "./boot-utils";

export default {
  components: { BaseSelection, Label },
  props: {
    column: Object,
    // 配置菜单 { alias: boolean; up: boolean, down: boolean, delete: boolean }
    moreConfig: Object
  },
  computed: {
    // 当所有菜单都不可用时，隐藏菜单按钮，显示箭头
    showMenu() {
      const config = this.moreConfig || {};
      return config.alias || config.up || config.down || config.delete;
    },
    // dimension 为 "value" 的字段支持设置聚合函数
    showAgg() {
      /** @type {import("../../widgets/type").IWidgetColumn} */
      const column = this.column || {};
      return column.columnId && column.dimension == DimensionType.Value;
    },
    iconClass() {
      /** @type {import("../../widgets/type").IWidgetColumn} */
      const column = this.column || {};
      return [Icon[column.columnType], "icon"];
    }
  },
  filters: { locale: utils.locale },
  methods: {
    async onAgg(evt) {
      const column = this.column || {};
      const options = utils.getAggOptions(column.columnType);
      const option = await showContextmenu(
        { options, width: "100px" },
        { source: evt.target, style: { zIndex: 2000 } }
      );
      if (option.key == "customary") {
        column.aggType = null;
      } else {
        column.aggType = option.key;
      }
    },
    async onMore(evt) {
      const moreConfig = this.moreConfig || {};
      // 展示菜单
      const options = utils.getContextmenus(moreConfig);
      const option = await showContextmenu(
        { options, width: "100px" },
        { source: evt.target, style: { zIndex: 2000 } }
      );
      // 修改名称
      if (option.key === "alias") {
        /** @type {{ edit(): void }} */
        const ref = this.$refs.label;
        ref && ref.edit();
      }
      // 执行回调函数
      const callbackName = `on${capitalize(option.key)}`;
      if (moreConfig[callbackName]) {
        moreConfig[callbackName](this.column);
      }
      // 触发事件
      this.$emit(option.key, this.column);
    }
  }
};
</script>

<style lang="less" scoped>
.ant-select-selection {
  & + & {
    margin-top: 12px;
  }
  /deep/ .ant-select-selection__rendered {
    display: flex;
    align-items: center;
    height: 30px;
  }
  .placeholder {
    flex: 1 1 0;
    color: #bfbfbf;
  }
  .icon {
    flex-shrink: 0;
    margin-right: 8px;
  }
  .label {
    flex: 1 1 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .button {
    flex-shrink: 0;
    border-radius: 4px;
    cursor: default;
    font-size: 12px;
    color: #646a73;
    padding: 5px;
    margin-left: 4px;
    line-height: 1;
    &:hover {
      background: #f0f0f0;
    }
  }
}
</style>
