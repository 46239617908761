<template>
  <div>
    <SelectField
      v-for="(item, index) in partitionColumns"
      :key="index"
      :value="item"
      :datasheet-id="datasheetId"
      :more-config="{
        alias: !!item.columnId,
        up: !!index,
        down: index < partitionColumns.length - 1,
        delete: partitionColumns.length > min,
        onUp: () => onMove(index, -1),
        onDown: () => onMove(index, 1),
        onDelete: () => onDelete(item)
      }"
      @select="onReplace($event, item, index)"
    />
    <SelectField v-if="showButton" :datasheet-id="datasheetId" @select="onAdd">
      <BaseSelection v-if="!partitionColumns.length" placeholder="请选择" />
      <div v-else style="margin-top: 8px" @click.self.capture.stop>
        <a>
          <i class="ndl-icon-plus"></i>
          <span>添加字段</span>
        </a>
      </div>
    </SelectField>
  </div>
</template>

<script>
import { IWidgetColumn } from "../../widgets/common.interface";
import { BaseSelection } from "../base";
import SelectField from "./SelectField";

export default {
  components: { SelectField, BaseSelection },
  props: {
    datasheetId: String,
    columns: Array,
    // 最少维度数量
    min: {
      type: Number,
      default: 0
    },
    // 最多维度数量
    max: {
      type: Number,
      default: Infinity
    },
    // 从 columns 中过滤出目标维度的方法
    partition: Function,
    // 字段选择后可通过 customize 方法处理，例如添加相应的标记、额外的属性
    customize: Function
  },
  computed: {
    partitionColumns() {
      const columns = this.columns || [];
      if (!this.partition) {
        return columns;
      }
      return columns.filter(this.partition);
    },
    // 若当前分区中的维度数量少于 max，则显示新增按钮
    showButton() {
      return this.partitionColumns.length < this.max;
    }
  },
  created() {
    this.revise();
  },
  watch: {
    // 因为 partitionColumns 是 computed 属性，会经常变化，会频繁触发 watcher
    // 而此处实际上只需要在其长度变化时才校准
    "partitionColumns.length"() {
      this.revise();
    },
    columns() {
      this.revise();
    },
    max() {
      this.revise();
    },
    min() {
      this.revise();
    }
  },
  methods: {
    // 校准 columns，补齐缺失项、删除多余项
    revise() {
      if (!this.partition) {
        return;
      }
      const columns = this.columns || [];
      const partitionLength = this.partitionColumns.length;
      if (partitionLength > this.max) {
        const maxItem = this.partitionColumns[this.max - 1];
        const maxItemIndex = columns.indexOf(maxItem);
        const revisedColumns = columns.filter(
          (item, index) => !(this.partition(item) && index > maxItemIndex)
        );
        columns.splice(0, Infinity, ...revisedColumns);
      }
      if (partitionLength < this.min) {
        const missingItems = Array.from(
          { length: this.min - partitionLength },
          () => {
            let column = new IWidgetColumn();
            if (this.customize) {
              column = this.customize(column) || column;
            }
            return column;
          }
        );
        columns.push(...missingItems);
      }
    },
    onReplace(source, target) {
      const columns = this.columns || [];
      const index = columns.indexOf(target);
      if (index >= 0) {
        source = this.customize(source) || source;
        columns.splice(index, 1, source);
      }
    },
    onAdd(column) {
      if (this.customize) {
        column = this.customize(column) || column;
      }
      if (this.columns) {
        this.columns.push(column);
      }
    },
    /**
     * 将位于 index 位置的元素向上/向下移动
     * @param {number} index 目标元素在 partitionColumns 中的索引
     * @param {number} dir 1: 向下移动，-1: 向上移动
     */
    onMove(index, dir = 1) {
      const columns = this.columns || [];
      const source = this.partitionColumns[index];
      const target = this.partitionColumns[index + dir];
      const sourceIndex = columns.indexOf(source);
      const targetIndex = columns.indexOf(target);
      if (!source || !target || sourceIndex < 0 || targetIndex < 0) {
        return;
      }
      // 在 columns 中交换两列的位置，勿在 partitionColumns 中交换
      // 且数组中通过下标交换位置不会触发 vue reactive watcher
      columns.splice(sourceIndex, 1, target);
      columns.splice(targetIndex, 1, source);
    },
    onDelete(column) {
      const columns = this.columns || [];
      const index = columns.indexOf(column);
      if (index >= 0) {
        columns.splice(index, 1);
      }
    }
  }
};
</script>
