import cloneDeep from "lodash/cloneDeep";
import { isEqual } from "@/utils/lib";
import Condition from "@/views/datamodel/models/condition";
import editFilter from "./filter-editor";

export const FilterDatasheet = {
  props: {
    datasheetId: String,
    value: Object
  },
  render() {
    if (!this.datasheetId) {
      return null;
    }
    const style = "display: inline-block; margin-top: 8px";
    const onClick = async evt => {
      const filter = cloneDeep(Condition.Filter.from(this.value));
      const keepState = cloneDeep(Condition.Filter.from(this.value));
      await editFilter({
        filter,
        source: evt.target,
        datasheetId: this.datasheetId
      });
      if (!isEqual(keepState, filter)) {
        this.$emit("input", filter);
      }
    };
    const filter = this.value || {};
    const len = filter.conditions && filter.conditions.length;
    return (
      <a style={style} onClick={onClick}>
        {"筛选数据" + (len ? `(${len})` : "")}
      </a>
    );
  }
};
