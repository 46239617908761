<template>
  <div v-on="$listeners" :class="[calssPrefix, calssPrefix + '--single']">
    <div
      :class="calssPrefix + '__rendered'"
      :style="showArrow ? '' : 'marginRight: 11px'"
    >
      <slot>
        <span v-if="placeholder" class="placeholder">
          {{ placeholder }}
        </span>
      </slot>
    </div>
    <span v-if="showArrow" class="ant-select-arrow">
      <slot name="arrow">
        <a-icon type="down" />
      </slot>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    showArrow: {
      type: Boolean,
      default: true
    },
    placeholder: String
  },
  computed: {
    calssPrefix() {
      return "ant-select-selection";
    }
  }
};
</script>

<style lang="less" scoped>
// 不知为何 antd 要加个伪元素，这里取消掉
// 否则 slot 显示为块级元素时会导致 __rendered 高度异常
.ant-select-selection__rendered {
  &:after {
    content: unset;
  }
  .placeholder {
    color: #bfbfbf;
  }
}
.ant-select-arrow .anticon-down {
  transition: transform 0.3s;
}
.ant-dropdown-open .anticon-down {
  transform: rotate(180deg);
}
</style>
