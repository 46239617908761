<template>
  <div class="divider">{{ label }}</div>
</template>

<script>
export default {
  props: {
    label: String
  }
};
</script>

<style lang="less" scoped>
.divider {
  // font-weight: 500;
  font-size: 13px;
  // color: #8f959e;
  color: rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  &:not(:first-child) {
    margin-top: 40px;
  }
}
</style>
