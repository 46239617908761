<template>
  <a-dropdown v-model="visible" destroy-popup-on-hide :trigger="['click']">
    <BaseSelection placeholder="选择图表">
      <div v-if="selectedChart" class="selection">
        <span class="icon" v-html="selectedChart.icon"></span>
        <span class="label">{{ selectedChart.name }}</span>
      </div>
    </BaseSelection>
    <BaseOverlay slot="overlay" :show-search="false">
      <ChartGroup
        v-for="group in groups"
        :key="group.type"
        :group="group"
        :current="value"
        @select="onSelect"
      />
    </BaseOverlay>
  </a-dropdown>
</template>

<script>
import * as Base from "../base";
import * as Widgets from "../../widgets";
import groups from "../../widgets/groups";
import ChartGroup from "./Group";

export default {
  components: { ...Base, ChartGroup },
  props: {
    value: String,
    datasource: Object
  },
  data() {
    return {
      groups,
      visible: false
    };
  },
  computed: {
    selectedChart() {
      const WidgetDescriber = Widgets.getWidget(this.value);
      if (!WidgetDescriber) {
        return null;
      }
      const name = WidgetDescriber.name;
      const group = groups.find(item => item.type == WidgetDescriber.group);
      return { name, icon: group && group.icon };
    }
  },
  methods: {
    onSelect(chartType) {
      this.visible = false;
      const prevWidget = Widgets.getWidget(this.value);
      const nextWidget = Widgets.getWidget(chartType);
      if (prevWidget && prevWidget.dimensions != nextWidget.dimensions) {
        const datasource = this.datasource || {};
        datasource.columns = [];
      }
      this.$emit("input", chartType);
    }
  }
};
</script>

<style lang="less" scoped>
.selection {
  display: flex;
  align-items: center;
  overflow: hidden;
  color: #3370ff;
  .icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    line-height: 1;
  }
  .icon /deep/ svg {
    width: 100%;
    height: 100%;
  }
}
</style>
