<template>
  <label :class="['form-item', { inline }]">
    <div :class="['label', { required }]">
      <slot name="label">{{ label }}</slot>
    </div>
    <div class="control">
      <slot></slot>
    </div>
  </label>
</template>

<script>
export default {
  props: {
    label: String,
    required: Boolean,
    inline: Boolean
  }
};
</script>

<style lang="less" scoped>
.form-item {
  display: block;
  margin-bottom: 24px;
  .label {
    white-space: nowrap;
    vertical-align: middle;
    overflow: hidden;
    margin-bottom: 8px;
  }
  .label.required:before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
  &.inline {
    display: flex;
    align-items: center;
  }
  &.inline .label {
    flex: 1 1 0;
    overflow: hidden;
    margin-bottom: 0;
  }
  &.inline .control {
    flex-shrink: 0;
  }
}
</style>
