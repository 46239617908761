import { Types as ColumnType } from "@/constant/column-types";
import { DimensionType } from "../../widgets/common.interface";
import SelectDimensions from "./SelectDimensions";

export { SelectDimensions };

export { default as SelectField } from "./SelectField";

export const SelectRows = {
  functional: true,
  render(h, context) {
    const props = context.props;
    /** @type { (column: import("../../widgets/type").IWidgetColumn) => boolean} */
    const partition = column => {
      const isRowDimension = column.dimension == DimensionType.Row;
      const customPartition = props.partition ? props.partition(column) : true;
      return isRowDimension && customPartition;
    };
    /** @type { (column: import("../../widgets/type").IWidgetColumn) => void} */
    const customize = column => {
      column.dimension = DimensionType.Row;
      if (props.customize) {
        props.customize(column);
      }
    };
    const newProps = { ...props, customize, partition };
    return <SelectDimensions {...{ props: newProps }} />;
  }
};

export const SelectGroups = {
  functional: true,
  render(h, context) {
    const props = context.props;
    /** @type { (column: import("../../widgets/type").IWidgetColumn) => boolean} */
    const partition = column => {
      const isGroupDimension = column.dimension == DimensionType.Group;
      const customPartition = props.partition ? props.partition(column) : true;
      return isGroupDimension && customPartition;
    };
    /** @type { (column: import("../../widgets/type").IWidgetColumn) => void} */
    const customize = column => {
      column.dimension = DimensionType.Group;
      if (props.customize) {
        props.customize(column);
      }
    };
    const newProps = { ...props, customize, partition };
    return <SelectDimensions {...{ props: newProps }} />;
  }
};

export const SelectValues = {
  functional: true,
  render(h, context) {
    const props = context.props;
    /** @type { (column: import("../../widgets/type").IWidgetColumn) => boolean} */
    const partition = column => {
      const isValueDimension = column.dimension == DimensionType.Value;
      const customPartition = props.partition ? props.partition(column) : true;
      return isValueDimension && customPartition;
    };
    /** @type { (column: import("../../widgets/type").IWidgetColumn) => void} */
    const customize = column => {
      column.dimension = DimensionType.Value;
      column.aggType = column.columnType == ColumnType.number ? "sum" : "count";
      if (props.customize) {
        props.customize(column);
      }
    };
    const newProps = { ...props, customize, partition };
    return <SelectDimensions {...{ props: newProps }} />;
  }
};
