var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.partitionColumns),function(item,index){return _c('SelectField',{key:index,attrs:{"value":item,"datasheet-id":_vm.datasheetId,"more-config":{
      alias: !!item.columnId,
      up: !!index,
      down: index < _vm.partitionColumns.length - 1,
      delete: _vm.partitionColumns.length > _vm.min,
      onUp: function () { return _vm.onMove(index, -1); },
      onDown: function () { return _vm.onMove(index, 1); },
      onDelete: function () { return _vm.onDelete(item); }
    }},on:{"select":function($event){return _vm.onReplace($event, item, index)}}})}),(_vm.showButton)?_c('SelectField',{attrs:{"datasheet-id":_vm.datasheetId},on:{"select":_vm.onAdd}},[(!_vm.partitionColumns.length)?_c('BaseSelection',{attrs:{"placeholder":"请选择"}}):_c('div',{staticStyle:{"margin-top":"8px"},on:{"!click":function($event){if($event.target !== $event.currentTarget){ return null; }$event.stopPropagation();}}},[_c('a',[_c('i',{staticClass:"ndl-icon-plus"}),_c('span',[_vm._v("添加字段")])])])],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }