<template>
  <div>
    <BaseFormItem label="显示图例" inline>
      <a-checkbox v-model="options.showLegend" />
    </BaseFormItem>
    <BaseFormItem label="显示数据标签" inline>
      <a-checkbox v-model="options.showSeriesLabel" />
    </BaseFormItem>
    <BaseFormItem label="显示所有坐标轴标签" inline>
      <a-checkbox v-model="options.forceCategoryAxisLableVisible" />
    </BaseFormItem>
    <BaseFormItem label="数据项使用不同颜色" inline>
      <a-checkbox v-model="options.colorByData" />
    </BaseFormItem>
    <BaseFormItem label="平滑曲线" inline>
      <a-checkbox v-model="options.smoothLine" />
    </BaseFormItem>
    <BaseFormItem label="使用两条 Y 轴分别展示" inline>
      <a-checkbox v-model="options.dualYAxis" />
    </BaseFormItem>
    <!-- <BaseFormItem label="格式化数值" inline>
      <a-checkbox v-model="options.formatLabel" />
    </BaseFormItem> -->
    <BaseFormItem label="配色方案">
      <SelectTheme v-model="options.theme" />
    </BaseFormItem>
  </div>
</template>

<script>
import * as Atoms from "../../atoms";
import { IWidgetOptions } from "./interface";

export default {
  inheritAttrs: false,
  components: { ...Atoms },
  props: {
    widget: Object,
    options: Object
  },
  created() {
    this.widget.options = new IWidgetOptions(this.options);
  }
};
</script>
