<template>
  <div>
    <BaseFormItem label="显示图例" inline>
      <a-checkbox v-model="options.showLegend" />
    </BaseFormItem>
    <BaseFormItem label="显示数据标签" inline>
      <a-checkbox v-model="options.showSeriesLabel" />
    </BaseFormItem>
    <BaseFormItem label="标签位置" inline>
      <a-select
        :default-value="options.seriesLabelDerc"
        v-model="options.seriesLabelDerc"
        style="width: 80px;margin-left:10px"
      >
        <a-select-option value="inside">内侧</a-select-option>
        <a-select-option value="left">左侧</a-select-option>
        <a-select-option value="right">右侧</a-select-option>
        <a-select-option value="top">上侧</a-select-option>
        <a-select-option value="bottom">下侧</a-select-option>
        <a-select-option value="insideRight">内部右侧</a-select-option>
        <a-select-option value="insideLeft">内部左侧</a-select-option>
        <a-select-option value="leftTop">左上</a-select-option>
        <a-select-option value="leftBottom">左下</a-select-option>
        <a-select-option value="rightTop">右上</a-select-option>
        <a-select-option value="rightBottom">右下</a-select-option>
      </a-select>
    </BaseFormItem>
    <BaseFormItem label="标签位置" inline>
      <a-select
        :default-value="options.seriesLabelData"
        v-model="options.seriesLabelData"
        style="width: 80px;margin-left:10px"
      >
        <a-select-option value="showData">数值</a-select-option>
        <a-select-option value="showConversion">转化率</a-select-option>
        <a-select-option value="showProportion">占比</a-select-option>
      </a-select>
    </BaseFormItem>
    <BaseFormItem label="显示标签名" inline>
      <a-checkbox v-model="options.showSeriesLabelName" />
    </BaseFormItem>
    <BaseFormItem label="最大映射宽度" inline>
      <a-input-number
        v-model="options.seriesMaxSize"
        :min="0"
        placeholder="请输入"
        style="width: 80px"
      />
    </BaseFormItem>
    <BaseFormItem label="间隔" inline>
      <a-input-number
        v-model="options.seriesGap"
        :min="0"
        placeholder="请输入"
        style="width: 80px"
      />
    </BaseFormItem>
    <BaseFormItem label="最小映射宽度" inline>
      <a-input-number
        v-model="options.seriesMinSize"
        :min="0"
        placeholder="请输入"
        style="width: 80px"
      />
    </BaseFormItem>
    <!-- <BaseFormItem label="格式化数值" inline>
      <a-checkbox v-model="options.formatLabel" />
    </BaseFormItem> -->
    <BaseFormItem label="配色方案">
      <SelectTheme v-model="options.theme" />
    </BaseFormItem>
  </div>
</template>

<script>
import * as Atoms from "../../atoms";
import { IWidgetOptions } from "./interface";

export default {
  inheritAttrs: false,
  components: { ...Atoms },
  props: {
    widget: Object,
    options: Object
  },
  created() {
    this.widget.options = new IWidgetOptions(this.options);
  }
};
</script>
