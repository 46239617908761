<template>
  <div>
    <BaseFormItem label="是否溢出省略" inline>
      <a-checkbox v-model="options.columnsEllipsis" />
    </BaseFormItem>

    <BaseFormItem label="是否固定第一列" inline>
      <a-checkbox v-model="options.fixedLeftColumns" />
    </BaseFormItem>

    <BaseFormItem label="每页显示数目" inline>
      <a-input-number
        v-model="options.basicPageSize"
        :min="0"
        placeholder="请输入"
        style="width: 100px"
      />
    </BaseFormItem>

    <BaseFormItem label="X轴显示滚动条大小" inline>
      <a-input-number
        v-model="options.scrollWidth"
        :min="0"
        placeholder="请输入"
        style="width: 100px"
      />
    </BaseFormItem>

    <BaseFormItem label="表格高度" inline>
      <a-input-number
        v-model="options.scrollHeight"
        :min="0"
        placeholder="请输入"
        style="width: 100px"
      />
    </BaseFormItem>

    <BaseFormItem label="每列长度设置" inline>
      <a-input-number
        v-model="options.basicColumnsWidth"
        :min="0"
        placeholder="请输入"
        style="width: 100px"
      />
    </BaseFormItem>
  </div>
</template>

<script>
import * as Atoms from "../../atoms";
import { IWidgetOptions } from "./interface";
export default {
  inheritAttrs: false,
  components: { ...Atoms },
  props: {
    widget: Object,
    options: Object
  },
  created() {
    this.widget.options = new IWidgetOptions(this.options);
  }
};
</script>
