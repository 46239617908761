<template>
  <div>
    <BaseFormItem label="数值千分位" inline>
      <a-checkbox v-model="options.thousandSeparated" />
    </BaseFormItem>
    <BaseFormItem label="保留小数位" inline>
      <a-input-number
        v-model="options.mantissa"
        :min="0"
        placeholder="请输入"
        style="width: 100px"
      />
    </BaseFormItem>
    <BaseFormItem label="格式化" inline>
      <a-select v-model="options.format" style="width: 100px">
        <a-select-option :value="null">无</a-select-option>
        <a-select-option value="%">百分比</a-select-option>
        <a-select-option value="¥">人民币</a-select-option>
        <a-select-option value="$">美元</a-select-option>
      </a-select>
    </BaseFormItem>
    <BaseFormItem label="字体大小" inline>
      <a-input-number
        v-model="options.fontSize"
        :min="10"
        placeholder="14"
        style="width: 100px"
      />
    </BaseFormItem>
    <BaseFormItem label="统计数值说明">
      <a-input v-model="options.title" placeholder="请输入" />
    </BaseFormItem>
    <BaseFormItem label="配色方案">
      <SelectColor v-model="options.color" />
    </BaseFormItem>
  </div>
</template>

<script>
import * as Atoms from "../../atoms";
import { IWidgetOptions } from "./interface";

export default {
  inheritAttrs: false,
  components: { ...Atoms },
  props: {
    widget: Object,
    options: Object
  },
  created() {
    this.widget.options = new IWidgetOptions(this.options);
  }
};
</script>
