<template>
  <div v-if="charts.length">
    <div class="group-label">{{ group.label }}</div>
    <!--
      antd dropdown 的 overlay 设置了 min-width。
      为了保持宽度不超出这个 min-width（超出太难看），
      这里用了一个 flex 容器将子元素包裹起来。
    -->
    <div class="flex-wrapper">
      <div class="group-content">
        <a-tooltip v-for="item in charts" :key="item.type" :title="item.name">
          <div
            v-html="item.icon"
            :class="['item', { checked: item.type == current }]"
            @click="$emit('select', item.type, item)"
          ></div>
        </a-tooltip>
        <div class="placeholder"></div>
        <div class="placeholder"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Widgets from "../../widgets";

export default {
  props: {
    group: Object,
    current: String
  },
  computed: {
    charts() {
      const group = this.group.type;
      return Object.values(Widgets.defs).filter(
        widget => widget.group == group
      );
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";
@cell-size: 60px;
@gutter: 12px;

.group-label {
  font-size: 12px;
  color: #646a73;
  font-weight: 500;
  line-height: 1.5;
  margin: @gutter @gutter 0 @gutter;
}
.flex-wrapper {
  display: flex;
  overflow: hidden;
}
.group-content {
  flex: 1 1 0;
  width: 0;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .item,
  .placeholder {
    flex-shrink: 0;
    width: @cell-size;
    margin: 0 @gutter;
  }
  .item {
    height: @cell-size;
    margin: @gutter;
    background: #f8f9fa;
    border-radius: 8px;
    cursor: pointer;
  }
  .item.checked {
    border: solid 1px @blue-6;
    background: @blue-1;
  }
}
</style>
