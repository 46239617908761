<template>
  <a-dropdown v-model="visible" destroy-popup-on-hide :trigger="['click']">
    <BaseSelection placeholder="请选择视图">
      <div v-if="selectedDatasheet" class="selection">
        <Iconfont :type="selectedDatasheet.icon" />
        <span :title="selectedDatasheet | title">
          {{ selectedDatasheet.nodeName }}
        </span>
      </div>
    </BaseSelection>
    <BaseOverlay slot="overlay" ref="overlay" :search.sync="search">
      <!-- 返回按钮 -->
      <div
        v-if="workspaceId && !search"
        class="ant-dropdown-menu-item"
        @click="workspaceId = null"
      >
        <i class="ndl-icon-corner-up-left"></i>
        <span style="margin-left: 8px">返回上级...</span>
      </div>
      <!-- 视图列表 -->
      <template v-for="node in nodes">
        <div
          :key="node.nodeId"
          :class="[
            'ant-dropdown-menu-item',
            {
              'ant-menu-item-disabled': !(node.isFolder || node.isDatasheet),
              'ant-menu-item-selected':
                node.nodeId == value ||
                (selectedDatasheet && selectedDatasheet.parentId == node.nodeId)
            }
          ]"
          @click="onSelect(node)"
        >
          <Iconfont :type="node.icon" />
          <span :title="node | title">{{ node.nodeName }}</span>
          <i v-if="node.isFolder" class="ndl-icon-chevron-right"></i>
        </div>
      </template>
      <!-- 空列表提示 -->
      <a-empty
        v-if="!nodes.length"
        description="暂无数据"
        style="padding: 32px 0"
      />
    </BaseOverlay>
  </a-dropdown>
</template>

<script>
import workspace from "@/views/datamodel/models/workspace";
import router from "@/router";
import { flatten } from "@/utils/array";
import Iconfont from "@/components/iconfont";
import * as Base from "../base";

export default {
  components: { ...Base, Iconfont },
  props: {
    value: String,
    datasource: Object
  },
  data() {
    return {
      visible: false,
      search: null,
      workspaceId: router.currentRoute.params.workspaceId
    };
  },
  computed: {
    datasheets() {
      return flatten(workspace.children);
    },
    nodes() {
      if (!this.search) {
        const node = workspace.getNodeById(this.workspaceId) || workspace;
        return node.children || [];
      }
      const word = this.search.toLowerCase();
      return this.datasheets.filter(item => {
        const nodeName = item.nodeName.toLowerCase();
        return nodeName.includes(word);
      });
    },
    selectedDatasheet() {
      return workspace.getNodeById(this.value);
    }
  },
  filters: {
    title(node) {
      const nodeName = node.nodeName;
      if (node.isFolder) {
        return nodeName;
      }
      const parent = node.parent;
      return parent ? `${nodeName}\n\n位于「${parent.nodeName}」` : nodeName;
    }
  },
  created() {
    if (this.selectedDatasheet) {
      this.workspaceId = this.selectedDatasheet.parent.nodeId;
    }
  },
  watch: {
    nodes() {
      this.$refs.overlay.scrollTop();
    }
  },
  methods: {
    onSelect(node) {
      if (node.isFolder) {
        this.workspaceId = node.nodeId;
      }
      if (!node.isDatasheet) {
        return;
      }
      this.$emit("input", node.nodeId, node);
      this.visible = false;
      this.search = null;
      if (this.value == node.nodeId) {
        return;
      }
      // 切换视图时，重置 datasource
      this.$emit("change", node.nodeId, node);
      const datasource = this.datasource || {};
      datasource.columns = [];
      datasource.filter = {};
    }
  }
};
</script>

<style lang="less" scoped>
.icon-symbol {
  font-size: 18px;
  margin-right: 8px;
  flex-shrink: 0;
}
.ant-menu-item-disabled .icon-symbol {
  opacity: 0.45;
}
.selection,
.ant-dropdown-menu-item {
  display: flex;
  align-items: center;
  span {
    flex: 1 1 0;
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.ant-dropdown-menu-item {
  margin: 4px 0;
}
</style>
