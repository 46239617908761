export class IWidgetOptions {
  constructor(opts) {
    opts = opts || {};

    this.scrollWidth = opts.scrollWidth || 500;

    this.scrollHeight = opts.scrollHeight || 240;

    this.fixedTableTitle = !!opts.fixedTableTitle;

    this.basicColumnsWidth = opts.basicColumnsWidth || 120;

    this.basicPageSize = opts.basicPageSize || 10;

    this.columnsEllipsis = !!opts.columnsEllipsis;

    this.fixedLeftColumns = !!opts.fixedLeftColumns;
  }
}
