import contextify from "@/components/contextify";
import Editor from "@/views/datamodel/components/context-filter/src/Filter";
import * as utils from "../../utils";

// mixins 无法实现重写 props 和 data 功能，故此处对 Editor 做了些处理
const MixinEditor = {
  ...Editor,
  props: {},
  data() {
    return {};
  }
};

const AdaptEditor = {
  mixins: [MixinEditor],
  props: {
    datasheetId: String,
    filter: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      columns: [],
      filterValueMap: {}
    };
  },
  computed: {
    computedColumns() {
      return this.columns;
    },
    filterValuePartMap() {
      return this.filterValueMap;
    }
  },
  async created() {
    if (this.datasheetId) {
      const { data } = await utils.getDatasheetColumns(this.datasheetId);
      this.columns = data;
    }
  },
  methods: {
    keyof: column => column.columnId,
    async getFilterValues(filter) {
      const map = this.filterValueMap || {};
      const columnId = filter.columnId;
      const datasheetId = this.datasheetId;
      if (!datasheetId || !columnId) {
        return;
      }
      const payload = { datasheetId, columnId };
      const { data } = await utils.getDistinctValue(payload);
      const values = data.values.map(value => ({ value }));
      this.$set(map, columnId, values);
      return data;
    }
  }
};

/**
 * @returns {Promise}
 */
export default function(args) {
  const opts = {
    ...args,
    inverse: { x: true, y: false },
    // z-index 需大于 <a-modal /> 的 z-index
    style: { overflow: "visible", zIndex: 2000 }
  };
  return contextify(AdaptEditor, opts).wait();
}
