<template>
  <BaseOverlay content-style="max-height: unset" :search.sync="search">
    <VirtualList
      data-key="columnId"
      style="max-height: 38vh; overflow: auto"
      :data-sources="filteredColumns"
      :data-component="ListItem"
      :extra-props="{ selected, onSelect }"
    />
    <div v-if="loading" style="text-align: center; padding: 50px 0">
      <a-spin />
    </div>
    <a-empty
      v-else-if="!filteredColumns.length"
      style="padding: 32px 0"
      :description="emptyTips"
    />
  </BaseOverlay>
</template>

<script>
import VirtualList from "vue-virtual-scroll-list";
import { Icon } from "@/constant/column-types";
import { BaseOverlay } from "../base";

const ListItem = {
  functional: true,
  render(h, context) {
    const { source: column, selected, onSelect } = context.props;
    const classList = ["ant-dropdown-menu-item"];
    if (selected == column.columnId) {
      classList.push("ant-menu-item-selected");
    }
    return (
      <div class={classList} onClick={onSelect.bind(null, column)}>
        <i class={[Icon[column.columnType], "icon"]}></i>
        <span class="label" title={column.columnName}>
          {column.columnName}
        </span>
      </div>
    );
  }
};

export default {
  components: { BaseOverlay, VirtualList },
  props: {
    loading: Boolean,
    selected: String,
    columns: Array,
    emptyTips: String
  },
  data() {
    return {
      ListItem,
      search: null
    };
  },
  computed: {
    filteredColumns() {
      if (!this.search) {
        return this.columns;
      }
      const word = this.search.toLowerCase();
      return this.columns.filter(item =>
        item.columnName.toLowerCase().includes(word)
      );
    }
  },
  methods: {
    onSelect(column) {
      this.$emit("select", column);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.ant-menu /deep/ .ant-dropdown-menu-item {
  display: flex;
  align-items: center;
  .icon {
    flex-shrink: 0;
    margin-right: 8px;
  }
  .label {
    flex: 1 1 0;
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
