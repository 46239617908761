<template>
  <div class="ant-menu" style="border-radius: 4px">
    <div v-if="showSearch" class="search-box">
      <i class="ndl-icon-search"></i>
      <input
        :value="search"
        type="text"
        placeholder="搜索..."
        @input="onInput"
      />
    </div>
    <div ref="content" class="overlay-content" :style="contentStyle">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
  props: {
    showSearch: {
      type: Boolean,
      default: true
    },
    search: String,
    contentStyle: [Object, String]
  },
  methods: {
    onInput: debounce(function(evt) {
      const value = evt.target.value;
      this.$emit("search", value);
      this.$emit("update:search", value);
    }, 300),
    scrollTop() {
      this.$nextTick(() => {
        this.$refs.content.scrollTop = 0;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.search-box {
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 12px;
  border-bottom: solid 1px #d9d9d9;
  & > i {
    flex-shrink: 0;
    margin-right: 6px;
  }
  & > input {
    border: none;
    outline: none;
    flex: 1 1 0;
    width: 0;
  }
  & > input::placeholder {
    color: #bfbfbf;
  }
}
.overlay-content {
  max-height: 38vh;
  overflow: auto;
}
</style>
