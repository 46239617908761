export class IWidgetOptions {
  constructor(opts) {
    opts = opts || {};
    // 默认不显示 label
    this.showSeriesLabel = !!opts.showSeriesLabel;
    // 默认显示 legend
    this.showLegend = opts.showLegend != false;
    this.formatLabel = !!opts.formatLabel;
    // colorBy 策略
    this.colorByData = !!opts.colorByData;
    this.forceCategoryAxisLableVisible = !!opts.forceCategoryAxisLableVisible;
    this.theme = opts.theme || null;
  }
}
