import {
  BaseFormItem,
  SelectDatasheet,
  FilterDatasheet,
  SelectChart,
  SelectRows,
  SelectValues,
  SelectGroups
} from "../../atoms";

const utils = {
  partitionLine: column => column.columnMeta.tag == "line",
  partitionBar: column => column.columnMeta.tag == "bar",
  customizeLine: column => (column.columnMeta.tag = "line"),
  customizeBar: column => (column.columnMeta.tag = "bar")
};

export default {
  inheritAttrs: false,
  props: {
    widget: Object,
    datasource: Object
  },
  render() {
    /** @type {import("../../models/widget").default} */
    const widget = this.widget || {};
    /** @type  {import("../type").IWidgetDatasource} */
    const datasource = this.datasource || {};
    return (
      <div>
        <BaseFormItem label="视图数据源" required>
          <SelectDatasheet
            vModel={datasource.datasheetId}
            datasource={datasource}
          />
          <FilterDatasheet
            vModel={datasource.filter}
            datasheet-id={datasource.datasheetId}
          />
        </BaseFormItem>
        <BaseFormItem label="图表类型" required>
          <SelectChart vModel={widget.type} datasource={datasource} />
        </BaseFormItem>
        <BaseFormItem label="维度（X 轴）" required>
          <SelectRows
            columns={datasource.columns}
            datasheet-id={datasource.datasheetId}
            min={1}
            max={1}
          />
        </BaseFormItem>
        <BaseFormItem label="折线数值（Y 轴）" required>
          <SelectValues
            columns={datasource.columns}
            datasheet-id={datasource.datasheetId}
            partition={utils.partitionLine}
            customize={utils.customizeLine}
            min={1}
          />
        </BaseFormItem>
        <BaseFormItem label="柱状数值（Y 轴）" required>
          <SelectValues
            columns={datasource.columns}
            datasheet-id={datasource.datasheetId}
            partition={utils.partitionBar}
            customize={utils.customizeBar}
            min={1}
          />
        </BaseFormItem>
        <BaseFormItem label="分组">
          <SelectGroups
            columns={datasource.columns}
            datasheet-id={datasource.datasheetId}
            min={0}
            max={1}
          />
        </BaseFormItem>
      </div>
    );
  }
};
