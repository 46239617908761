import {
  BaseFormItem,
  SelectDatasheet,
  FilterDatasheet,
  SelectChart,
  SelectValues
} from "../../atoms";

const utils = {
  partitionMajor: column => column.columnMeta.tag == "major",
  partitionMinor: column => column.columnMeta.tag == "minor",
  customizeMajor: column => (column.columnMeta.tag = "major"),
  customizeMinor: column => (column.columnMeta.tag = "minor")
};

export default {
  inheritAttrs: false,
  props: {
    widget: Object,
    datasource: Object
  },
  render() {
    /** @type {import("../../models/widget").default} */
    const widget = this.widget || {};
    /** @type  {import("../type").IWidgetDatasource} */
    const datasource = this.datasource || {};
    return (
      <div>
        <BaseFormItem label="视图数据源" required>
          <SelectDatasheet
            vModel={datasource.datasheetId}
            datasource={datasource}
          />
          <FilterDatasheet
            vModel={datasource.filter}
            datasheet-id={datasource.datasheetId}
          />
        </BaseFormItem>
        <BaseFormItem label="图表类型" required>
          <SelectChart vModel={widget.type} datasource={datasource} />
        </BaseFormItem>
        <BaseFormItem label="主指标" required>
          <SelectValues
            columns={datasource.columns}
            datasheet-id={datasource.datasheetId}
            partition={utils.partitionMajor}
            customize={utils.customizeMajor}
            min={1}
            max={1}
          />
        </BaseFormItem>
        <BaseFormItem label="副指标" required>
          <SelectValues
            columns={datasource.columns}
            datasheet-id={datasource.datasheetId}
            partition={utils.partitionMinor}
            customize={utils.customizeMinor}
            min={1}
            max={1}
          />
        </BaseFormItem>
      </div>
    );
  }
};
