export class IWidgetOptions {
  constructor(opts) {
    opts = opts || {};
    // 默认不显示 label
    this.showSeriesLabel = opts.showSeriesLabel != false;
    // 默认显示 legend
    this.showLegend = opts.showLegend != false;
    this.formatLabel = !!opts.formatLabel;
    this.theme = opts.theme || null;
  }
}
