export class IWidgetOptions {
  constructor(opts) {
    opts = opts || {};
    this.title = opts.title || null;
    this.color = opts.color || null;
    this.fontSize = opts.fontSize || null;
    // 千分位
    this.thousandSeparated = !!opts.thousandSeparated;
    // 小数位
    this.mantissa = "mantissa" in opts ? Number(opts.mantissa) || 0 : null;
    // 格式化: ¥ | ¥ | %
    this.format = opts.format || null;
  }
}
