<template>
  <div>
    <div class="palette">
      <span
        v-for="item in colors"
        :key="item"
        :style="{ backgroundColor: item }"
        :class="['unit', { checked: value == item }]"
        @click="$emit('input', item)"
      ></span>
      <span v-for="n in 8" :key="n" class="placeholder"></span>
    </div>
    <a-input :value="value" @input="$emit('input', $event.target.value)">
      <i
        slot="suffix"
        class="ndl-icon-palette-fill"
        :style="{ color: value || '#bfbfbf', fontSize: '18px' }"
      ></i>
    </a-input>
  </div>
</template>

<script>
export default {
  props: {
    value: String
  },
  data() {
    return {
      visible: false,
      colors: [
        "#373c43",
        "#3471ff",
        "#4854e6",
        "#36c724",
        "#13c1ff",
        "#ffc60b",
        "#ff8800",
        "#f76a64",
        "#5470c6",
        "#91cc75",
        "#fac858",
        "#ee6666",
        "#73c0de",
        "#3ba272",
        "#ea7ccc",
        "#9a60b4",
        "rgb(85, 134, 255)",
        "rgb(85, 205, 255)",
        "rgb(48, 194, 139)",
        "rgb(82, 196, 27)",
        "rgb(255, 235, 58)",
        "rgb(255, 112, 139)",
        "rgb(227, 62, 56)",
        "rgb(50, 50, 50)"
      ]
    };
  }
};
</script>

<style lang="less" scoped>
.palette {
  margin-bottom: 8px;
  border-radius: 6px;
  background: #f2f3f5;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @size: 26px;
  @gutter: 2px;
  .unit,
  .placeholder {
    width: 26px;
    margin: 0 @gutter;
  }
  .unit {
    height: 26px;
    margin: @gutter;
    border-radius: 50%;
    border: solid 2px transparent;
    padding: 2px;
    background-clip: content-box;
    cursor: pointer;
    &.checked,
    &:hover {
      border-color: #40a9ff;
    }
  }
}
</style>
