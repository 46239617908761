export class IWidgetOptions {
  constructor(opts) {
    opts = opts || {};
    this.title = opts.title || null;
    this.color = opts.color || null;
    this.fontSize = opts.fontSize || null;
    // 千分位
    this.thousandSeparated = !!opts.thousandSeparated;
    // 小数位
    this.mantissa = "mantissa" in opts ? Number(opts.mantissa) || 0 : null;
    // 格式化: ¥ | ¥ | %
    this.format = opts.format || null;

    this.title2 = opts.title2 || null;
    this.color2 = opts.color2 || null;
    this.fontSize2 = opts.fontSize2 || null;
    // 千分位
    this.thousandSeparated2 = !!opts.thousandSeparated2;
    // 小数位
    this.mantissa2 = "mantissa2" in opts ? Number(opts.mantissa2) || 0 : null;
    // 格式化: ¥ | ¥ | % | ^ | + | - |v
    this.format2 = opts.format2 || null;
  }
}
