import { Types } from "@/constant/column-types";

export function locale(type) {
  if (type === null) {
    return "原值";
  }
  const map = {
    sum: "求和",
    max: "最大值",
    min: "最小值",
    avg: "平均值",
    count: "记录数",
    distinct: "唯一记录数",
    customary: "原值"
  };
  return map[type] || "自定义";
}

export function getAggOptions(columnType) {
  const make = key => ({ key, label: locale(key) });
  const options = [make("sum")];
  if (columnType == Types.number) {
    options.push(make("max"), make("min"), make("avg"));
  }
  options.push(make("count"), make("customary"), make("distinct"));
  return options;
}

export function getContextmenus(opts) {
  opts = Object.assign(
    {
      alias: true,
      up: true,
      down: true,
      delete: true
    },
    opts
  );
  return [
    { key: "alias", label: "修改名称", disabled: !opts.alias },
    { key: "up", label: "上移字段", disabled: !opts.up },
    { key: "down", label: "下移字段", disabled: !opts.down },
    {
      key: "delete",
      label: "删除字段",
      labelClass: "ndl-color-red",
      disabled: !opts.delete
    }
  ];
}
