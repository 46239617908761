var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-dropdown',{attrs:{"destroy-popup-on-hide":"","trigger":['click']},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('BaseSelection',{attrs:{"placeholder":"请选择视图"}},[(_vm.selectedDatasheet)?_c('div',{staticClass:"selection"},[_c('Iconfont',{attrs:{"type":_vm.selectedDatasheet.icon}}),_c('span',{attrs:{"title":_vm._f("title")(_vm.selectedDatasheet)}},[_vm._v(" "+_vm._s(_vm.selectedDatasheet.nodeName)+" ")])],1):_vm._e()]),_c('BaseOverlay',{ref:"overlay",attrs:{"slot":"overlay","search":_vm.search},on:{"update:search":function($event){_vm.search=$event}},slot:"overlay"},[(_vm.workspaceId && !_vm.search)?_c('div',{staticClass:"ant-dropdown-menu-item",on:{"click":function($event){_vm.workspaceId = null}}},[_c('i',{staticClass:"ndl-icon-corner-up-left"}),_c('span',{staticStyle:{"margin-left":"8px"}},[_vm._v("返回上级...")])]):_vm._e(),_vm._l((_vm.nodes),function(node){return [_c('div',{key:node.nodeId,class:[
          'ant-dropdown-menu-item',
          {
            'ant-menu-item-disabled': !(node.isFolder || node.isDatasheet),
            'ant-menu-item-selected':
              node.nodeId == _vm.value ||
              (_vm.selectedDatasheet && _vm.selectedDatasheet.parentId == node.nodeId)
          }
        ],on:{"click":function($event){return _vm.onSelect(node)}}},[_c('Iconfont',{attrs:{"type":node.icon}}),_c('span',{attrs:{"title":_vm._f("title")(node)}},[_vm._v(_vm._s(node.nodeName))]),(node.isFolder)?_c('i',{staticClass:"ndl-icon-chevron-right"}):_vm._e()],1)]}),(!_vm.nodes.length)?_c('a-empty',{staticStyle:{"padding":"32px 0"},attrs:{"description":"暂无数据"}}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }