<template>
  <a-dropdown v-model="visible" :trigger="['click']">
    <BaseSelection>
      <Palette :colors="colors" class="selection" />
    </BaseSelection>
    <BaseOverlay slot="overlay" :show-search="false">
      <Palette
        v-for="item in themes"
        :key="item.name"
        :colors="item.palette"
        :class="['theme-item', { checked: (value || 'default') == item.name }]"
        @click="onSelect(item)"
      />
    </BaseOverlay>
  </a-dropdown>
</template>

<script>
import * as Base from "../base";
import Palette from "./Palette";
import ThemeProvider from "./theme-provider";

export default {
  components: { ...Base, Palette },
  props: {
    value: String
  },
  data() {
    return {
      visible: false
    };
  },
  computed: {
    colors() {
      return ThemeProvider.getColors(this.value);
    },
    themes() {
      return ThemeProvider.themes;
    }
  },
  methods: {
    onSelect(theme) {
      this.$emit("input", theme.name);
      this.visible = false;
    }
  }
};
</script>

<style lang="less" scoped>
.selection {
  height: 30px;
  margin-right: 8px;
}
.theme-item {
  height: 42px;
  padding: 0 12px;
  cursor: pointer;
  &:hover {
    background: #f2f2f2;
  }
  &.checked {
    background: #e6f7ff;
  }
}
</style>
