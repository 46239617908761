<template>
  <div>
    <div style="margin-bottom:10px">
      <span class="tipLabel" style="margin-left:120px">主指标</span>
      <span class="tipLabel" style="margin-left:50px">副指标</span>
    </div>
    <BaseFormItem label="数值千分位" inline>
      <a-checkbox
        style="margin-right:60px"
        v-model="options.thousandSeparated"
      />
      <a-checkbox
        style="margin-right:40px"
        v-model="options.thousandSeparated2"
      />
    </BaseFormItem>
    <BaseFormItem label="保留小数位" inline>
      <a-input-number
        v-model="options.mantissa"
        :min="0"
        placeholder="请输入"
        style="width: 80px"
      />
      <a-input-number
        v-model="options.mantissa2"
        :min="0"
        placeholder="请输入"
        style="width: 80px;margin-left:10px"
      />
    </BaseFormItem>
    <BaseFormItem label="格式化" inline>
      <a-select v-model="options.format" style="width: 80px">
        <a-select-option :value="null">无</a-select-option>
        <a-select-option value="%">百分比</a-select-option>
        <a-select-option value="¥">人民币</a-select-option>
        <a-select-option value="$">美元</a-select-option>
      </a-select>
      <a-select v-model="options.format2" style="width: 80px;margin-left:10px">
        <a-select-option :value="null">无</a-select-option>
        <a-select-option value="up">提升</a-select-option>
        <a-select-option value="down">降低</a-select-option>
        <a-select-option value="add">增加</a-select-option>
        <a-select-option value="reduce">减少</a-select-option>
        <a-select-option value="$">美元</a-select-option>
        <a-select-option value="%">百分比</a-select-option>
        <a-select-option value="￥">人民币</a-select-option>
      </a-select>
    </BaseFormItem>
    <BaseFormItem label="字体大小" inline>
      <a-input-number
        v-model="options.fontSize"
        :min="10"
        placeholder="14"
        style="width: 80px"
      />
      <a-input-number
        v-model="options.fontSize2"
        :min="10"
        placeholder="14"
        style="width: 80px;margin-left:10px"
      />
    </BaseFormItem>
    <BaseFormItem label="数值主指标说明">
      <a-input v-model="options.title" placeholder="请输入" />
    </BaseFormItem>

    <BaseFormItem label="数值副指标说明">
      <a-input v-model="options.title2" placeholder="请输入" />
    </BaseFormItem>

    <BaseFormItem label="主指标配色方案">
      <SelectColor v-model="options.color" />
    </BaseFormItem>

    <BaseFormItem label="副指标配色方案">
      <SelectColor v-model="options.color2" />
    </BaseFormItem>
  </div>
</template>

<script>
import * as Atoms from "../../atoms";
import { IWidgetOptions } from "./interface";

export default {
  inheritAttrs: false,
  components: { ...Atoms },
  data() {
    return {};
  },
  props: {
    widget: Object,
    options: Object
  },
  created() {
    this.widget.options = new IWidgetOptions(this.options);
  }
};
</script>
<style>
.tipLabel {
  font-size: 14px;
  text-align: right;
}
</style>
