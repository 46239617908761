<template>
  <input
    v-if="editing"
    v-focus
    class="label-input"
    :value="value"
    :placeholder="value"
    @keydown.enter="onEnter"
    @keydown.esc="onEsc"
    @blur="onBlur"
    @click.stop
  />
  <span v-else :title="value">{{ value }}</span>
</template>

<script>
import { focus } from "@/utils/directives";

export default {
  props: {
    value: String
  },
  directives: { focus },
  data() {
    return {
      editing: false
    };
  },
  methods: {
    edit() {
      this.editing = true;
    },
    onBlur(evt) {
      const value = evt.target.value.trim();
      if (value) {
        this.$emit("input", value);
      }
      this.editing = false;
    },
    onEnter(evt) {
      evt.target.blur();
    },
    onEsc(evt) {
      evt.target.value = this.value;
      evt.target.blur();
    }
  }
};
</script>

<style lang="less" scoped>
.label-input {
  line-height: 26px;
  border: none;
  outline: none;
  padding: 0 4px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  &::placeholder {
    color: #bfbfbf;
  }
}
</style>
