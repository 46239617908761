<template>
  <div class="palette" v-on="$listeners">
    <span
      v-for="item in colors"
      :key="item"
      :style="{ background: item }"
    ></span>
  </div>
</template>

<script>
export default {
  props: {
    colors: Array
  }
};
</script>

<style lang="less" scoped>
.palette {
  display: flex;
  align-items: center;
  overflow: hidden;
  span {
    height: 16px;
    flex: 1 1 0;
  }
  span:first-child {
    border-radius: 4px 0 0 4px;
  }
  span:last-child {
    border-radius: 0 4px 4px 0;
  }
}
</style>
